import React, { useEffect, useState } from 'react';
import Title from './Title';

import partner1 from '../../assets/Images/partner1.png';
import partner2 from '../../assets/Images/partner2.svg';
import partner3 from '../../assets/Images/partner3.svg';
import partner4 from '../../assets/Images/parnter4.svg';
import partner5 from '../../assets/Images/partner5.svg';

const partners = [partner1, partner2, partner3, partner4, partner5];


const partnerNames = [
    'Partner 1',
    'Partner 2',
    'Partner 3',
    'Partner 4',
    'Partner 5'
];

const PartnerSlider = () => {
    const [currentPartnerIndex, setCurrentPartnerIndex] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentPartnerIndex(prevIndex => (prevIndex + 1) % partners.length);
        }, 3000);
        return () => clearInterval(intervalId);
    }, []);
    return (
        <section
            className="bg-white mt-5"
            style={{ paddingTop: '5rem', paddingBottom: '4rem' }}
        >

            <div className="container px-4 mt-5">
                <Title
                    Title={"Our trusted partners"}
                    Description={
                        " "
                    }
                />
                <div className="position-relative overflow-hidden px-4 pt-3 mt-5" style={{ paddingTop: '2.5rem' }}>
                    <div
                        className="d-flex"
                        style={{
                            transform: `translateX(-${currentPartnerIndex * (100 / partners.length)}%)`,
                            transition: 'transform 1s ease-in-out'
                        }}
                    >
                        {partners.map((partner, index) => (
                            <div
                                key={index}
                                className="d-flex justify-content-center px-4 flex-shrink-0"
                                style={{ width: '20%' }} // 5 items = 100/5
                            >
                                <img
                                    src={partner}
                                    alt={partnerNames[index]}
                                    className="img-fluid mx-auto"
                                    style={{ height: '4rem', objectFit: 'contain' }}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <div className="d-flex gap-2">
                            {partners.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => setCurrentPartnerIndex(index)}
                                    style={{
                                        height: '0.8rem',
                                        width: '0.8rem',
                                        borderRadius: '50%',
                                        border: 'none'
                                    }}
                                    className={index === currentPartnerIndex ? 'bg-success' : 'bg-secondary'}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PartnerSlider;
