import React, { useState, useEffect } from 'react';

const testimonials = [
    {
        quote: "This product has revolutionized our business process.",
        author: "John Doe, CEO of Acme Inc."
    },
    {
        quote: "Outstanding service and support—highly recommended!",
        author: "Jane Smith, Founder of InnovateX"
    },
    {
        quote: "A game changer in our industry. Five stars!",
        author: "Alex Johnson, Director at TechSolutions"
    }
];

const TestimonialSlider = () => {
    const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

    // Auto slide every 3 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTestimonialIndex(prevIndex => (prevIndex + 1) % testimonials.length);
        }, 3000);
        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <section className="py-5 bg-white" style={{ paddingTop: '8rem' }}>
            <div className="container" style={{ maxWidth: '768px' }}>
                <div className="d-flex justify-content-center mb-3">
                    <div className="bg-success rounded-circle p-3">
                        <svg
                            style={{ width: '2rem', height: '2rem' }}
                            className="text-white"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L9.758 4.03c0 0-.218.052-.597.144C8.97 4.222 8.737 4.278 8.472 4.345c-.271.05-.56.187-.882.312C7.272 4.799 6.904 4.895 6.562 5.123c-.344.218-.741.4-1.091.692C5.132 6.116 4.723 6.377 4.421 6.76c-.33.358-.656.734-.909 1.162C3.219 8.33 3.02 8.778 2.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C2.535 17.474 4.338 19 6.5 19c2.485 0 4.5-2.015 4.5-4.5S8.985 10 6.5 10zM17.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L20.758 4.03c0 0-.218.052-.597.144-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162C14.219 8.33 14.02 8.778 13.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C13.535 17.474 15.338 19 17.5 19c2.485 0 4.5-2.015 4.5-4.5S19.985 10 17.5 10z" />
                        </svg>
                    </div>
                </div>

                <div style={{ overflow: 'hidden' }}>
                    <div
                        style={{
                            transform: `translateX(-${currentTestimonialIndex * 100}%)`,
                            display: 'flex',
                            transition: 'transform 0.5s ease-in-out'
                        }}
                    >
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="w-100 flex-shrink-0 text-center px-2">
                                <h2 className="h4 mb-3">{testimonial.quote}</h2>
                                <p className="text-muted">{testimonial.author}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-3">
                    <div className="d-flex gap-2">
                        {testimonials.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentTestimonialIndex(index)}
                                style={{ height: '0.8rem', width: '0.8rem' }}
                                className={`rounded-circle border-0 ${index === currentTestimonialIndex ? 'bg-success' : 'bg-secondary'}`}
                            ></button>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialSlider;
