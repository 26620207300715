// const env = "local"
const env = "dev"
// const env = "prod"

function getPlatFormUrl() {
  if (env === "local") {
    return "http://localhost:3000"
  }
  if (env === "dev") {
    return "https://dev.fob.sofodev.co"
  }
  if (env === "prod") {
    return "https://app.tradereboot.com"
  }
}

function getPlatFormBackendUrl() {
  if (env === "local") {
    return "http://localhost:3000"
  }
  if (env === "dev") {
    return "https://dev-server.fob.sofodev.co"
  }
  if (env === "prod") {
    return "https://server.tradereboot.com"
  }
}

function getWebsiteBackendURL() {
  if (env === "local") {
    return "http://localhost:3002"
  }
  if (env === "dev") {
    return "https://backendwebdev.sofodev.co"
  }
  if (env === "prod") {
    return "https://backend.tradereboot.com"
  }
}

export const platformURL = getPlatFormUrl()
export const platformBackendURL = getPlatFormBackendUrl()
export const websiteBackendURL = getWebsiteBackendURL()
export const googleApiKey = ""