import { platformBackendURL } from "../config";

// export const validate_number_field = str => {
//   return str.replace(/[^0-9\.]/g, "").replace(/(?<=\..*)\./g, '')
// }

export const validate_text_field = str => {
  return str.replace(/^\s?/, '')
}

export function calcHaversineDistance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1Rad = toRad(lat1);
  var lat2Rad = toRad(lat2);


  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

function toRad(Value) {
  return Value * Math.PI / 180;
}

export function avatarUrl(fileName) {
  return platformBackendURL + "/getavatar?avatar=" + fileName
}