import React from "react";
import { NavLink } from "react-router-dom";
import Banner from '../../images/Home1.png';
import { Fade } from "react-reveal";

const Banner_Component = () => {
    return (
        <>
            <div id="HomeBg" className="container-fluid pb-3 d-flex justify-content-center align-items-center">
                <div className="w-100 container p-3">
                    <div className="w-100 row sec-cen">
                        <Fade left>
                            {/* <div className="col-xl-7 col-lg-7 col-sm-10 col-md-10 home-sec m-auto">
                                <h1>YOUR PRODUCT.
                                    <span className="d-block" style={{ color: "#65E4FD" }}>THEIR MARKET.</span>
                                    <span className="d-block" style={{ color: "#65E4FD" }}>OUR PLATFORM.</span>
                                    <span className="d-block" style={{ color: "#65E4FD" }}> A GATEWAY TO GLOBAL TRADE. </span></h1>
                                <h5 className="head-but">An one-stop digital trading solution designed to simplify global trade for exporters and importers.<span style={{ color: "#65E4FD" }}></span> </h5>
                                <div className="  head-but pt-4">
                                    <NavLink to="/contact-us" target="_blank">
                                        <button className="btn Seemore my-2 my-sm-0 ml-3" type="submit">Market place</button>

                                        <button className="btn text-white btn Seemore my-2 my-sm-0 ml-3"
                                            style={{
                                                background: "linear-gradient(to right, #01BD00, #005700)",
                                                opacity: "1"
                                            }}
                                            onMouseEnter={(e) => (e.target.style.opacity = "1")}
                                            onMouseLeave={(e) => (e.target.style.opacity = "0.9")}
                                        >
                                            Start a free trial
                                        </button>


                                    </NavLink>
                                    <NavLink to="/solutions">
                                        <button className="btn Seemore-outline my-2 my-sm-0" type="submit">Solution</button></NavLink>
                                </div>
                            </div> */}
                            <div className="position-relative h-100 d-flex flex-column justify-content-center align-items-center px-4 px-md-5 mx-auto" >
                                <h1
                                    className="fs-1 fw-normal text-white text-center"
                                    style={{
                                        fontFamily: "Outfit",
                                        maxWidth: "52rem",
                                        whiteSpace: "pre-line",
                                    }}
                                >
                                    <span className="d-block d-md-inline">YOUR PRODUCT,</span>{" "}
                                    <span className="d-block d-md-inline">THEIR MARKET<span className="d-md-none">,</span></span>
                                    <br className="d-none d-md-block" />
                                    <span className="d-block">OUR PLATFORM,<span className="d-md-none">,</span></span>
                                    <span className="d-block">A GATEWAY TO GLOBAL TRADE</span>
                                </h1>

                                <p className="mt-3 text-white fs-6 fs-md-5 mx-auto text-center" style={{ fontFamily: "Outfit", maxWidth: "42rem" }}>
                                    An one-stop digital trading solution designed to simplify global trade for exporters and importers.
                                </p>
                                <div className="mt-8">
                                    <a
                                        href="https://app.tradereboot.com/registration"
                                        target="_blank"
                                        rel="noreferrer"
                                        className=" text-white px-4 py-3 rounded d-inline-block y-50 w-10" style={{
                                            background: 'linear-gradient(to right, #01BD00, #005700)',
                                            textDecoration: "none",
                                            transition: 'opacity 0.3s'
                                        }}
                                        onMouseOver={(e) => e.currentTarget.style.opacity = "0.9"}
                                        onMouseOut={(e) => e.currentTarget.style.opacity = "1"}
                                    >
                                        Start a free trial
                                    </a>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-xl-5 col-lg-5 col-sm-10 col-md-10 home-img">
                                {/* <img src={Banner} width="550" height="550" alt="Banner Image" className="img-fluid" /> */}
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Banner_Component;