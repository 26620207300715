import React from "react";
import AboutUsBanner from '../../images/AboutUs.svg';
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

const AboutUsBanner_Component = () => {
    return (
        <>
            <div id="HomeBg" className="container-fluid pb-5" >
                <div className="container p-3">
                    <div className="row">
                        <Fade left>
                            <div className="col-xl-7 col-lg-7 col-sm-10 col-md-10 home-sec m-auto">
                                <h5 className="fw-bold">ABOUT US</h5>
                                <h1 className="">A <span className="TextColor"> Global </span> Blockchain platform / marketplace for <span className="TextColor">Commodity</span> Trade</h1>

                                <ul className="ListStyleImage mt-4">
                                    <li className="text-light fs-5 mx-2"> TradeReboot Is A Trade Tech Company And Provides Digitalized Solutions For The International Commodity Trade By Utilizing Technology Such As Blockchain, Artificial Intelligence, Machine Learning And By Integrating ICC Guidelines For Security Purposes. </li>

                                </ul>
                                <div className="pt-4">
                                    <a href="https://app.tradereboot.com/login" target="_blank"> <button className="btn Seemore my-2 my-sm-0 ml-3" type="submit">Get Started</button></a>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-xl-5 col-lg-5 col-sm-10 col-md-10 home-img">
                                <img src={AboutUsBanner} width="450" height="450" alt="Banner Image" className="img-fluid" />
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AboutUsBanner_Component;