import React from "react";
import "../../styles/Shipping.css";
import Arrival from "./Arrival";
import DepatureArrival from "./DepatureArrival";
import PortsofDischarge from "./PortsofDischarge";
import PortsofLoading from "./PortsofLoading";
import ShippingLines from "./ShippingLines";
import Services from "./Services";
import Trashipments from "./Transhipments";
import TransitTime from "./TransitTime";


const ShippingData_Component = ({ shippingData }) => {
    console.log('shippingDataaaa => ', shippingData);
    return (
        <>
            <div className="card market-filters my-5">
                <Trashipments />
                <ShippingLines sealine={shippingData.data} />
                <Services services={shippingData.data} />

            </div>
        </>
    );
}
export default ShippingData_Component;