import React, { useState } from 'react';
import Title from './Title';

// Data for each solution category
const solutionData = {
    freight: [
        "Ocean Freight",
        "Trucking Freight",
        "Air Freight",
        "Customs",
        "Warehousing"
    ],
    office: [
        "Order management",
        "Booking management",
        "Inventory management",
        "Buyer Discovery",
        "MIS Reporting"
    ],
    financial: [
        "Trade finance",
        "Insurance",
        "Trade Advisory",
        "Duty Drawback"
    ]
};

// Categories configuration
const categories = [
    { key: 'freight', label: 'Freight Forwarding' },
    { key: 'office', label: 'Office management' },
    { key: 'financial', label: 'Financial Services' }
];

const Solutions = () => {
    const [solutionCategory, setSolutionCategory] = useState('freight');

    return (
        <section
            className=""
            style={{ paddingTop: '5rem', paddingBottom: '4rem' }}
        >
            <div className="container px-4">
                <Title Title="Solutions" Description="" />

                {/* Category Buttons */}
                <div className="d-flex flex-wrap justify-content-center gap-2 mb-4 mt-4">
                    {categories.map((category) => (
                        <button
                            key={category.key}
                            onClick={() => setSolutionCategory(category.key)}
                            className={`btn m-2 ${solutionCategory === category.key ? 'btn-success' : 'btn-light text-dark'}`}
                        >
                            {category.label}
                        </button>
                    ))}
                </div>

                {/* Grid for Freight Forwarding Solutions */}
                {solutionCategory === 'freight' && (
                    <div className="row justify-content-center g-4">
                        {solutionData.freight.map((solution, index) => (
                            <div
                                key={index}
                                className="d-flex align-items-center justify-content-center mx-auto"
                                style={{
                                    backgroundColor: '#DBFFDB',
                                    width: '100%',
                                    maxWidth: '243px',
                                    height: '150px',
                                    borderRadius: '0.5rem'
                                }}
                            >
                                <h4
                                    className="text-center mb-0"
                                    style={{ fontSize: '1rem', fontWeight: '600' }}
                                >
                                    {solution}
                                </h4>
                            </div>
                        ))}
                    </div>
                )}

                {/* Grid for Office Management Solutions */}
                {solutionCategory === 'office' && (
                    <div className="row justify-content-center g-4">
                        {solutionData.office.map((solution, index) => (
                            <div
                                key={index}
                                className="d-flex align-items-center justify-content-center mx-auto"
                                style={{
                                    backgroundColor: '#DBFFDB',
                                    width: '100%',
                                    maxWidth: '243px',
                                    height: '150px',
                                    borderRadius: '0.5rem'
                                }}
                            >
                                <h4
                                    className="text-center mb-0"
                                    style={{ fontSize: '1rem', fontWeight: '600' }}
                                >
                                    {solution}
                                </h4>
                            </div>
                        ))}
                    </div>
                )}

                {/* Grid for Financial Services Solutions */}
                {solutionCategory === 'financial' && (
                    <div className="row justify-content-center g-4">
                        {solutionData.financial.map((solution, index) => (
                            <div
                                key={index}
                                className="d-flex align-items-center justify-content-center mx-auto"
                                style={{
                                    backgroundColor: '#DBFFDB',
                                    width: '100%',
                                    maxWidth: '243px',
                                    height: '150px',
                                    borderRadius: '0.5rem'
                                }}
                            >
                                <h4
                                    className="text-center mb-0"
                                    style={{ fontSize: '1rem', fontWeight: '600' }}
                                >
                                    {solution}
                                </h4>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default Solutions;
