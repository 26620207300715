import React from "react";
import { Fade } from "react-reveal";
import LogiTechImage from "../../../images/INSPECT-TECH-IMG.gif";


const InspectBanner_Component = () => {
    return (
        <>
            <div className="container-fluid p-0" id="InspectTech-Bg" >
                <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 mx-auto py-5">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto text-center ">
                            <Fade left>
                                <img src={LogiTechImage} alt="Competetive Image" className="img-fluid" />
                            </Fade>
                        </div>
                        <Fade right>

                            <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto my-3">
                                <h4 className="text-light fw-bold text-uppercase">Inspect-Tech</h4>
                                <h3 className="text-light fw-bold">Innovate <span className="TextColor">Labour-intensive </span>processes</h3>
                                <ul className="ListStyleImage mt-4">
                                    <li className="text-light fs-5">TradeReboot uses blockchain technology to digitize documents shared between participants.</li>
                                    <li className="text-light fs-5 mt-3">Import and export locations have complex and varied documentation requirements.</li>
                                    <li className="text-light fs-5 mt-3">Authenticity of documents is essential for government authorities – and your customers.</li>
                                    <li className="text-light fs-5 mt-3">You'll have access to embedded version control, traceability and data integrity to minimize re-work.</li>
                                </ul>
                                <div className="pt-4">
                                    <a href="https://app.tradereboot.com/login" target="_blank"> <button className="btn Seemore my-2 my-sm-0 ml-3" type="submit">Get Started</button></a>
                                </div>
                            </div>
                        </Fade>

                    </div>
                </div>
            </div>
        </>
    );
}
export default InspectBanner_Component;