import React from "react";
import Title from "./Title";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useNumber from "../About Us/useNumber";
import Team1 from '../../assets/Images/team1.svg';
import Team2 from '../../assets/Images/team2.svg';
import Team3 from '../../assets/Images/team3.svg';
import Team4 from '../../assets/Images/team4.svg';
import { Link } from "react-router-dom";


export const Chevron_Right = ({ color, size }) => {
    return (
        <svg
            height={size || "24px"}
            width={size || "24px"}
            fill={color || "#aaa"}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
        >
            {" "}
            <path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z" />
        </svg>
    );
};
export const Chevron_Left = ({ color, size }) => {
    return (
        <svg
            height={size || "24px"}
            width={size || "24px"}
            fill={color || "#aaa"}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
        >
            {" "}
            <path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z" />
        </svg>
    );
};

const teamMembers = [
    {
        name: "CA Dhiraj Khandelwal",
        role: "Founder & CEO",
        description: "Commerce Graduate from DU, CA from ICAI, CA with 25+ years of experience, 10+ years experience in trading",
        image: Team1,
        alt: "CA Dhiraj Khandelwal"
    },
    {
        name: "CA Aditya Gupta",
        role: "Co-Founder",
        description: "Engineering Graduate, 10+ years of experience in Startup Advisory & Govt Solution",
        image: Team2,
        alt: "CA Aditya Gupta"
    },
    {
        name: "Shubham Jain",
        role: "Co-Founder, CTO & CMO",
        description: "IIT Bombay alumnus, ex-Tata, Daikin (Japan), driving growth with his deep expertise in product, technology and international trade.",
        image: Team3,
        alt: "Shubham Jain"
    },
    {
        name: "Aman Mittal",
        role: "Product Manager",
        description: "Software Engineer, ex Mphasis Ltd.(FedEx - Germany), experienced in tech, product, operations and growth.",
        image: Team4,
        alt: "Aman Mittal"
    },
];

const TeamCard = ({ member }) => (
    <div className="col-12 col-sm-6 col-lg-3">
        <div className="text-center">
            <div
                style={{
                    width: '10rem',
                    height: '10rem',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    margin: '0 auto 1.5rem'
                }}
            >
                <img
                    src={member.image}
                    alt={member.alt}
                    className="w-100 h-100"
                    style={{ objectFit: 'cover' }}
                />
            </div>
            <h5 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{member.name}</h5>
            <p className="text-muted mb-1" style={{ fontWeight: '500' }}>{member.role}</p>
            <p className="text-secondary small text-center">{member.description}</p>
        </div>
    </div>
);
const MeetOurTeam_Component = () => {
    return (
        <>

            <div className="TeamSection overflow-hidden position-relative mt-2">
                <div className="">
                    <div className="text-center mt-5">
                        <Title
                            Title={"Meet Our Team"}
                            Description={
                                " "
                            }
                        />
                        <section style={{ paddingBottom: '4rem' }} className="bg-white">
                            <div className="container">
                                {/* <h2 className="text-center mb-5" style={{ fontSize: '2rem', fontWeight: 'bold' }}>Meet our team</h2> */}
                                <div className="row g-4">
                                    {teamMembers.map((member, index) => (
                                        <TeamCard key={index} member={member} />
                                    ))}
                                </div>
                            </div>
                        </section>
                        {/* <RenderInPc /> */}
                    </div>

                </div>
            </div>
        </>
    );
}
export default MeetOurTeam_Component;

const RenderInPc = () => {
    const { number, increase_Handler, decrease_Handler } = useNumber(
        TeamData.slice(0, 2).length - 1
    );
    console.log(number);

    return (
        <Carousel
            autoPlay={true}
            emulateTouch={true}
            infiniteLoop={true}
            showStatus={false}
            showArrows={false}
            interval={1000 * 5}
            centerMode={false}
            showIndicators={false}
            selectedItem={number}
        >
            {TeamData.slice(0, 2).map((data, index) => {
                return (
                    <div key={index} className="TeamSection_Box ">
                        <Team
                            PersonName={data.PersonName}
                            Position={data.Position}
                            About={data.About}
                            src={data.Src}
                            increase_Handler={increase_Handler}
                            decrease_Handler={decrease_Handler}
                            Linkedin={data.Linkedin}
                        />
                    </div>
                );
            })}
        </Carousel>
    );
};
const Team = ({
    src,
    PersonName,
    About,
    Position,
    increase_Handler,
    decrease_Handler,
    Linkedin
}) => {
    return (
        <div className="TeamSection_Card">
            <div className="TeamSection_Card_Left">
                <img src={src} alt="Hello World " />
                <div className="Card_Left">

                    <button onClick={decrease_Handler}>
                        <Chevron_Left color="#fff" size={"17px"} />
                    </button>
                    <button onClick={increase_Handler}>
                        <Chevron_Right color="#fff" size={"17px"} />
                    </button>
                </div>
            </div>
            <div className="TeamSection_Card_Right">
                <div>
                    <h2>{PersonName.toLowerCase()}</h2>
                    <a href={Linkedin} style={{ 'color': 'black' }} target="_blank">
                        <span className="iconify mx-2" data-icon="akar-icons:linkedin-fill"></span></a>

                </div>
                <div>
                    <span>{Position}</span>
                </div>
                <div className="h-75">
                    <p>{About}</p>
                </div>
                <div className="">
                    <Link to="/home/book-appointment" target="_blank"> <button className="btn Seemore">Book Appointment</button> </Link>
                </div>
            </div>
        </div>
    );
};

const TeamData = [
    {
        PersonName: `CA Dhiraj Khandelwal`,
        Position: `Founder & CEO`,
        About: `Commerce Graduate from DU, CA from ICAI, CA with 25+ years of experience, 10+ years experience in
                trading`,
        Src: Team1,
        Linkedin: 'https://www.linkedin.com/in/ayushi-shetty-2b61a31b7'
    },
    {
        PersonName: `Arpit Kadakia `,
        Position: `Relationship & Marketing Manager`,
        About: `Experienced Management professional with expertise in Team Management, Liquidity Management and Trade Surveillance. Expertise in developing processes to facilitate timely and accurate settlement of corporate action events and trade settlements across various asset classes, along with a strong knowledge in the implementation of regulations related to foreign exchange (FX), Trade Finance products. Proficient in building and leading high performing teams.`,
        Src: "",
        Linkedin: 'https://www.linkedin.com/in/arpit-kadakia-29289a16a/'
    },
];